import { memo } from 'react';
import {Handle, Position} from "reactflow";

//import testImage from "../assets/img/image4.png"
const LessonNode = ({ data }) => {
    return (
        <>
            {/*<h2>VideoNode</h2>*/}
            {/*<img src={testImage} style={{height:150}}/>*/}
            <Handle
                type="target"
                position={Position.Bottom}
                style={{ background: '#555', visibility:'hidden' }}
                //onConnect={(params) => console.log('handle onConnect', params)}
            />
            <Handle
                type="source"
                position={Position.Top}
                id="a"
                style={{ visibility: 'hidden' }}
            />
        </>
    )
}

export default memo(LessonNode);