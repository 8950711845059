
import testImage from "./assets/img/image4.png"

const rootPosition = {x: 300, y: 1100}

const structuralNodeConfig = {
    width: 400,
    height: 200,
    level_1_offset_x: 800,
    level_1_offset_y: 550,
    level_2_offset_x: 250,
    level_2_offset_y: 350,
}

const lessonNodeConfig = {
    width: 100,
    height: 100,
    lesson_node_offset_y: 250,
}

// This is just for prototyping and needs to be replaced later
// TODO: some mechanism for the positions needs to be figured out
const sn_placeholderNode_Position = {
    x: (rootPosition.x - structuralNodeConfig.level_1_offset_x),
    y: (rootPosition.y - structuralNodeConfig.level_1_offset_y)
}
const sn_aiNode_Position = {
    x: (rootPosition.x + structuralNodeConfig.level_1_offset_x),
    y: (rootPosition.y - structuralNodeConfig.level_1_offset_y)
}

const sn_aiPerceptionNode_Position = {
    x: sn_aiNode_Position.x - structuralNodeConfig.level_2_offset_x,
    y: sn_aiNode_Position.y - structuralNodeConfig.level_2_offset_y
}

const sn_aiDecisionsNode_Position = {
    x: sn_aiNode_Position.x + structuralNodeConfig.level_2_offset_x,
    y: sn_aiNode_Position.y - structuralNodeConfig.level_2_offset_y
}

const ln_placeholder_basePosition = {
    x: sn_placeholderNode_Position.x + (structuralNodeConfig.width/2) - (lessonNodeConfig.width/2) ,
    y: sn_placeholderNode_Position.y
}

const ln_aiPerception_basePosition = {
    x: sn_aiPerceptionNode_Position.x + (structuralNodeConfig.width/2) - (lessonNodeConfig.width/2) ,
    y: sn_aiPerceptionNode_Position.y
}

const ln_aiDecision_basePosition = {
    x: sn_aiDecisionsNode_Position.x + (structuralNodeConfig.width/2) - (lessonNodeConfig.width/2) ,
    y: sn_aiDecisionsNode_Position.y
}

// ------------------------------------------------------------------

const lessonNodeDummyData = [
    // nodes first sn
    {
        id: 'ln-sn11-01',
        color: '#2c59d7',
        data: {},
        position: {
            x: ln_placeholder_basePosition.x ,
            y: ln_placeholder_basePosition.y - lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn11-02',
        color: '#2c59d7',
        data: {},
        position: {
            x: ln_placeholder_basePosition.x ,
            y: ln_placeholder_basePosition.y - 2 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn11-03',
        color: '#2c59d7',
        data: {},
        position: {
            x: ln_placeholder_basePosition.x ,
            y: ln_placeholder_basePosition.y - 3 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn11-04',
        color: '#2c59d7',
        data: {},
        position: {
            x: ln_placeholder_basePosition.x ,
            y: ln_placeholder_basePosition.y - 4 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    // nodes ai perception --------------------------------------------------
    {
        id: 'ln-sn21-01',
        color: '#E76F51',
        data: {},
        position: {
            x: ln_aiPerception_basePosition.x ,
            y: ln_aiPerception_basePosition.y - 1 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn21-02',
        color: '#E76F51',
        data: {},
        position: {
            x: ln_aiPerception_basePosition.x ,
            y: ln_aiPerception_basePosition.y - 2 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn21-03',
        color: '#E76F51',
        data: {},
        position: {
            x: ln_aiPerception_basePosition.x ,
            y: ln_aiPerception_basePosition.y - 3 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn21-04',
        color: '#E76F51',
        data: {},
        position: {
            x: ln_aiPerception_basePosition.x ,
            y: ln_aiPerception_basePosition.y - 4 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    // nodes ai decisions --------------------------------------------------
    {
        id: 'ln-sn22-01',
        color: '#E76F51',
        data: {},
        position: {
            x: ln_aiDecision_basePosition.x ,
            y: ln_aiDecision_basePosition.y - 1 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn22-02',
        color: '#E76F51',
        data: {},
        position: {
            x: ln_aiDecision_basePosition.x ,
            y: ln_aiDecision_basePosition.y - 2 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn22-03',
        color: '#E76F51',
        data: {},
        position: {
            x: ln_aiDecision_basePosition.x ,
            y: ln_aiDecision_basePosition.y - 3 * lessonNodeConfig.lesson_node_offset_y
        },
    },
    {
        id: 'ln-sn22-04',
        color: '#E76F51',
        data: {},
        position: {
            x: ln_aiDecision_basePosition.x ,
            y: ln_aiDecision_basePosition.y - 4 * lessonNodeConfig.lesson_node_offset_y
        },
    },
]

const lessonNodeStyle = {
    commonStyle: {
        width: 100,
        height: 100,
        borderRadius: '50px',
        backgroundSize:'cover',
    }
}

const structuralNodeDummyData = [
    {
        id: 'sn-level1-1',
        color: '#2c59d7',
        data: {
            title: "Structural Node Placeholder",
            description: "Placeholder for the description for this structural node",
        },
        position: sn_placeholderNode_Position,
    },
    {
        id: 'sn-level1-2',
        color: '#669BBC',
        data: {
            title: "Structural Node Placeholder",
            description: "Placeholder for the description for this structural node",
        },
        position: {
            x: (rootPosition.x ),
            y: (rootPosition.y - structuralNodeConfig.level_1_offset_y)
        },
    },
    {
        id: 'sn-level1-3',
        color: '#E76F51',
        data: {
            title: "AI",
            description: "Here we will learn about how our unplayable Characters will make decisions",
        },
        position: sn_aiNode_Position,
    },
    {
        id: 'sn-level2-3-1',
        color: '#E76F51',
        data: {
            title: "AI-Perception",
            description:
                "What information needs to be exposed to an AI Agent in order for it " +
                "to be able to make reasonanle decisions",
        },
        position: sn_aiPerceptionNode_Position,
    },
    {
        id: 'sn-level2-3-2',
        color: '#E76F51',
        data: {
            title: "AI-Decisions",
            description: "How does the AI Agent use the data from his perception to make deicisions",
        },
        position: sn_aiDecisionsNode_Position,
    },
]

const structuralNodeStyle = {
    commonStyle: {
        borderRadius: '20px',
        width: structuralNodeConfig.width,
        height: structuralNodeConfig.height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        color: "black",
    }
}

const structuralNodes = [
    {
        id: 'rn',
        type: 'resultNode',
        position: rootPosition,
        data: { label: '1' },
        style: {
            width: 220,
            height: 300,
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px"
        },
    },
    {
        id: 'sn-level1-1',
        type: 'structuralNode',
        position: structuralNodeDummyData[0].position,
        style: {
            border:"10px solid " + structuralNodeDummyData[0].color,
            ...structuralNodeStyle.commonStyle,
        },
        data: structuralNodeDummyData[0].data,
    },
    {
        id: 'sn-level1-3',
        type: 'structuralNode',
        position: structuralNodeDummyData[2].position,
        style: {
            border:"10px solid " + structuralNodeDummyData[2].color,
            ...structuralNodeStyle.commonStyle,
        },
        data: structuralNodeDummyData[2].data,
    },
    {
        id: 'sn-level2-3-1',
        type: 'structuralNode',
        position: structuralNodeDummyData[3].position,
        style: {
            border:"10px solid " + structuralNodeDummyData[2].color,
            ...structuralNodeStyle.commonStyle,
        },
        data: structuralNodeDummyData[3].data,
    },
    {
        id: 'sn-level2-3-2',
        type: 'structuralNode',
        position: structuralNodeDummyData[4].position,
        style: {
            border:"10px solid " + structuralNodeDummyData[2].color,
            ...structuralNodeStyle.commonStyle,
        },
        data: structuralNodeDummyData[4].data,
    },
];

const lessonNodes = [
    {
        id: lessonNodeDummyData[0].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[0].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[0].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[1].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[1].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[1].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[2].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[2].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[2].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[3].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[3].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[3].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    // nodes ai perception --------------------------------------------------
    {
        id: lessonNodeDummyData[4].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[4].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[4].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[5].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[5].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[5].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[6].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[6].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[6].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[7].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[7].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[7].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    // nodes ai decisions --------------------------------------------------
    {
        id: lessonNodeDummyData[8].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[8].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[8].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[9].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[9].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[9].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[10].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[10].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[10].color,
            ...lessonNodeStyle.commonStyle
        }
    },
    {
        id: lessonNodeDummyData[11].id,
        type: 'lessonNode',
        position: lessonNodeDummyData[11].position,
        style: {
            backgroundImage:'url(' + testImage + ')',
            border:"5px solid " + lessonNodeDummyData[11].color,
            ...lessonNodeStyle.commonStyle
        }
    },
]

export const nodes = [
    ...structuralNodes,
    ...lessonNodes
]

export const edges = [
    // level 1 ***********************************************
    { id: 'rn-sn1', source: "rn", target: 'sn-level1-1'},
    { id: 'rn-sn3', source: "rn", target: 'sn-level1-3'},
    // level 2 ***********************************************
    { id: 'sn3-sn4', source: "sn-level1-3", target: 'sn-level2-3-1'},
    { id: 'sn3-sn5', source: "sn-level1-3", target: 'sn-level2-3-2'},
    // lesson nodes placeholder ***********************************************
    { id: 'sn1-ln-11-01', source: "sn-level1-1", target: 'ln-sn11-01'},
    { id: 'ln-11-01-02', source: "ln-sn11-01", target: 'ln-sn11-02'},
    { id: 'ln-11-02-03', source: "ln-sn11-02", target: 'ln-sn11-03'},
    { id: 'ln-11-03-04', source: "ln-sn11-03", target: 'ln-sn11-04'},
    // lesson nodes ai-perception ***********************************************
    { id: 'sn3-ln-21-01', source: "sn-level2-3-1", target: 'ln-sn21-01'},
    { id: 'ln-21-01-02', source: "ln-sn21-01", target: 'ln-sn21-02'},
    { id: 'ln-21-02-03', source: "ln-sn21-02", target: 'ln-sn21-03'},
    { id: 'ln-21-03-04', source: "ln-sn21-03", target: 'ln-sn21-04'},
    // lesson nodes ai-decision ***********************************************
    { id: 'sn4-ln-22-01', source: "sn-level2-3-2", target: 'ln-sn22-01'},
    { id: 'ln-22-01-02', source: "ln-sn22-01", target: 'ln-sn22-02'},
    { id: 'ln-22-02-03', source: "ln-sn22-02", target: 'ln-sn22-03'},
    { id: 'ln-22-03-04', source: "ln-sn22-03", target: 'ln-sn22-04'}
]