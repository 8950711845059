import { memo } from 'react';
import {Handle, Position} from "reactflow";
import styled from "styled-components";

const Node = styled.div`
  //background: #1babb0;
  color: #1a1a1a;
  text-align: center;
  
`

const Title = styled.h3`
    //color: #BF4F74;
`

const StructuralNode = ({ data }) => {

    //console.log(data);

    return (
        <Node>
            <Handle
                type="target"
                position={Position.Bottom}
                style={{ background: '#555', visibility:'hidden' }}
                //onConnect={(params) => console.log('handle onConnect', params)}
            />
            <Handle
                type="source"
                position={Position.Top}
                id="a"
                style={{ visibility: 'hidden' }}
            />

            <Title>{data.title}</Title>
            <p>{data.description}</p>
        </Node>
    )
}

export default memo(StructuralNode)