import React, {useCallback, useEffect, useState} from 'react';
import ReactFlow, {useEdgesState, useNodesState, ReactFlowProvider} from 'reactflow';
import { QueryClient, QueryClientProvider} from "react-query";
import 'reactflow/dist/style.css';
import './nodes/nodeStyle.css'

import LessonNode from "./nodes/LessonNode.jsx";
import ResultNode from "./nodes/ResultNode.jsx";
import StructuralNode from "./nodes/StructuralNode.jsx";

import testImage from "./assets/img/image4.png"

import {nodes as initialNodes, edges as initialEdges, nodes} from './nodes-edges.js';
import {Lesson} from "./components/Lesson.jsx";

const queryClient = new QueryClient()

const nodeTypes = {
  lessonNode: LessonNode,
  resultNode: ResultNode,
  structuralNode: StructuralNode,
}


function FlowWrapper({onSelectedNodeChanged}) {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  /*const testSelection = useCallback(
      (params) =>
          console.log(params.nodes),
          []
  )*/

  const onNodeClick = (event, node) => {
    console.log('click node', node);
    onSelectedNodeChanged(node);
  }



  /*const onConnect = useCallback(
      (params) =>
          setEdges((eds) => addEdge({ ...params, animated: true, style: { stroke: '#fff' } }, eds)),
      []
  );*/

  return (
      <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          minZoom={0}
          //onSelectionChange={testSelection}
          onNodeClick={onNodeClick}
      />
  )
}



export default function App() {

  const [selectedNode, setSelectedNode] = useState({})

  const selectedNodeChanged = (selectedNode) => {

    //console.log("selected node: ")
    //console.log(selectedNode)

    setSelectedNode(selectedNode);
  }

  return (
      <QueryClientProvider client={queryClient}>
        <div id={"flow-container"} style={{ width: '50vw', height: '90vh' }}>
          <ReactFlowProvider>
            <FlowWrapper onSelectedNodeChanged={selectedNodeChanged}></FlowWrapper>
          </ReactFlowProvider>
        </div >
        <div style={{
          width: '40vw',
          height: '100vh',
          backgroundColor: '#202020',
          position: "absolute",
          top: '0',
          right: '0'
        }}>
          <Lesson selectedNode={selectedNode}></Lesson>
        </div>
      </QueryClientProvider>


  );
}
