import { memo } from 'react';
import {Handle, Position} from "reactflow";

import dummyResultImage from "../assets/img/Rectangle 90.png";
import unityImage from "../assets/img/U_Logo_MadeWith_RichBlack_RGB 1.png";

const ResultNode = ({data}) => {
    return (
        <>
            <Handle
                type="target"
                position={Position.Bottom}
                style={{ background: '#555', visibility:'hidden' }}
                //onConnect={(params) => console.log('handle onConnect', params)}
            />
            <Handle
                type="source"
                position={Position.Top}
                id="a"
                style={{ visibility: 'hidden' }}
            />
            <p style={{color: "black", fontWeight:900, margin: 10}}>Final Result</p>
            <p style={{color: "#DF4320", fontWeight:900, fontSize: 22, margin: 10}}>Pacman Clone</p>
            <img src={dummyResultImage} style={{height:100}}/>
            <img src={unityImage} style={{height:40, marginTop:20 }}/>
        </>
    )
}

export default memo(ResultNode);