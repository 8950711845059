import {useQuery} from "react-query";
import axios from "axios";

const API_BASE_URL = 'http://localhost:4000'

export const useFetchAllLessons = () => {
    const response =  useQuery('lessons', () => {
        return  axios.get(`${API_BASE_URL}/lessons`)
    })
    return response;
}

export const useFetchLesson = (id) => {
    return useQuery(['lessonById', id], () => {
        try {
            return axios.get(`${API_BASE_URL}/lessons/${id}`)
        } catch (e) {
            return { error: e}
        }
    });
}

export const useFetchDefaultLesson = () => {
    return useQuery('defaultLesson', () => {
        return axios.get(`${API_BASE_URL}/lessons/default`)
    })
}
